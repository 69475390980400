<template>
  <div class="w-full flex flex-col justify-center items-center gap-10">
    <div class="w-11/12 flex flex-col items-center lg:items-start">
      <div class="w-full max-w-xl mb-5">
        <h2 class="mb-5 text-blue-dark text-left text-3xl"><b>Seguimiento de quejas</b></h2>
      </div>
      <ComplaintsTableHeader :user="user" />
      <ComplaintList class="max-w-xl lg:max-w-full" />
    </div>
    <div class="w-11/12 max-w-2xl">
      <AccordionList :items="accordionList" />
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import ComplaintList from '@/components/Customer/Complaint/ComplaintList'
import ComplaintsTableHeader from '@/components/Customer/Complaint/ComplaintsTableHeader'

export default {
  components: {
    ComplaintList,
    ComplaintsTableHeader
  },
  mounted(){},
  data(){
    return {
      accordionList: [
        {title: '¿Que significan los estados de una queja?', body: 'El consumidor financiero encontrara el estado de su queja, que hace referencia: Recibida: Queja o reclamo interpuesta por el consumidor financiero recibida por la entidad vigilada, el defensor del consumidor financiero o la Superintendencia Financiera de Colombia. Abierta: Queja o reclamo en gestión por parte de la entidad vigilada. Desde que se radica la queja hasta antes que la entidad vigilada de la respuesta. Cerrada: Queja con respuesta final de la entidad vigilada. Cuando el consumidor está conforme con la respuesta o si no manifestó su conformidad en el periodo definido después de la respuesta.'}
      ]
    }
  },
  computed: mapState({
    user: (state)=>{
      return state.session.user
    }
  })
}
</script>

<style>

</style>