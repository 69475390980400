<template>
    <div class="w-full px-5 py-5 my-4 flex text-left max-w-xl lg:max-w-none rounded-lg bg-white shadow-lg">
        <!-- Mobile -->
        <div class="w-full flex flex-col items-start lg:hidden">
            <span class="w-full mb-2 flex flex-col md:flex-col justify-between items-start col-span-2"><b>Id. de radicado</b><p class="text-left">{{complaint.codigo_queja}}</p></span>
            <span class="w-full mb-2 flex flex-col md:flex-col justify-between items-start col-span-2"><b>Entidad</b><p class="text-left">{{complaint.entidad_cod}}</p></span>
            <span class="w-full mb-2 flex flex-col md:flex-col justify-between items-start col-span-3"><b>Motivo de queja</b><p class="text-left">{{complaint.macro_motivo_cod.name}}</p></span>
            <span class="w-full mb-2 flex flex-col md:flex-col justify-between items-start col-span-1"><b>Estado</b><b :class="status.class" class="text-left">{{complaint.estado_cod}}</b></span>
            <span class="w-full mt-4 flex flex-col md:flex-col justify-end items-start col-span-1 text-blue"><router-link :to="'detail/'+complaint.codigo_queja" append><b class="text-right">Ver Detalle</b></router-link></span>
        </div>
        <!-- Desktop -->
        <div class="w-full hidden lg:grid lg:grid-cols-12">
            <span class="col-span-3 text-sm pr-2 my-auto">{{complaint.codigo_queja}}</span>
            <span class="col-span-2 text-sm pr-2 my-auto">{{complaint.entidad_cod}}</span>
            <span class="col-span-4 text-sm pr-3 my-auto">{{complaint.macro_motivo_cod.name}}</span>
            <span class="col-span-1 text-sm my-auto flex items-start" :class="status.class"><ComplaintStatusLabel v-if="complaint.estado_cod" :status="complaint.estado_cod" danger="true" /></span>
            
            <span class="col-span-1 text-sm my-auto flex items-start" ><ComplaintStatusLabel  :status= "duplicity" danger="true" /></span>
            <div class="flex justify-center col-span-1 text-sm pr-2 text-blue h-min"><img src="@/assets/icons/eye.svg" class="h-5 my-auto"><router-link :to="'detail/'+complaint.codigo_queja" class="h-min my-auto" append><b>Ver</b></router-link></div>
        </div>
    </div>
</template>

<script>
import ComplaintStatusLabel from './ComplaintStatusLabel'

export default {
    props: ['complaint'],
    components: {
        ComplaintStatusLabel
    },
    data(){
        return {
            statusClass: {
                'text-blue': true
            }
        }
    },
    computed: {
        status: function(){
            let complaintStatus = {
                class: {},
                text: ''
            }
            switch (this.complaint.estado_cod){
                case 'Recibida': 
                    complaintStatus.text = 'Recibida'
                    complaintStatus.class = {'text-green-500': true}
                    break;
                case 'Abierta': 
                    complaintStatus.text = 'Abierta'
                    complaintStatus.class = {'text-yellow-300': true}
                    break;
                case 'Abierta en conciliación': 
                    complaintStatus.text = 'Abierta en Conciliación'
                    complaintStatus.class = {'text-yellow-600': true}
                    break;
                case 'Cerrada': 
                    complaintStatus.text = 'Cerrada'
                    complaintStatus.class = {'text-red-500': true}
            }
            return complaintStatus;
        },
        duplicity: function(){
            let duplicity = "";
            if (this.complaint.codigo_queja_principal)
              duplicity = "Unificada en " + this.complaint.codigo_queja_principal;
            else if (this.complaint.estado_duplicidad === "Duplicada")
              duplicity = "Duplicada";
            else duplicity = "Es queja principal";
            return duplicity
        }
    }
}
</script>

<style>

</style>